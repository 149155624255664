/* eslint-disable consistent-return */
/*
    Middleware to check if the user is a guest.
    Otherwise, redirect to the dashboard page.
*/
export default defineNuxtRouteMiddleware(() => {
    const currentWins = useCookie('currentWins');

    if (!currentWins.value) {
        const localePath = useLocalePath();
        return navigateTo(localePath('/dashboard'));
    }
});
